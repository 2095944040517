import { useCallback, useState } from 'react';
import { message } from 'antd';
import useApi from '../useApi';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignTemplate, CreateCampaignTemplatePayloadType } from '../../utils/type';
import { decreaseSpinner, increaseSpinner } from '../../store/ui/actions';
import { setCampaign, setEditingTemplate } from '../../store/campaign/actions';
import {
  addCampaignsTemplate,
  removeCampaignTemplate,
  setCampaignTemplates,
  updateCampaignsTemplate
} from '../../store/auth/actions';
import errorParser from '../../utils/errors';
import { campaignTemplatesSelector } from '../../store/auth/selectors';

interface iBrandCampaignTemplates {
  errors: Record<string, string[]>;
  busy: boolean;
  campaignTemplates: CampaignTemplate[];
  getCampaignTemplates: (id: number, spinner?: boolean) => void;
  createCampaignTemplate: (
    brandId: number,
    data: CreateCampaignTemplatePayloadType,
    callback?: (template: CampaignTemplate | null) => void
  ) => void;
  updateCampaignTemplate: (
    brandId: number,
    id: number,
    data: CreateCampaignTemplatePayloadType,
    callback?: () => void
  ) => void;
  deleteCampaignTemplate: (id: number, templateId: number) => void;
}

const useBrandCampaignTemplates = (): iBrandCampaignTemplates => {
  const api = useApi();
  const dispatch = useDispatch();
  const campaignTemplates = useSelector(campaignTemplatesSelector);
  const [busy, setBusy] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState({});

  const getCampaignTemplates = useCallback(
    (id: number, spinner = true) => {
      if (spinner) dispatch(increaseSpinner());
      dispatch(setEditingTemplate(true));
      api
        .getBrandCampaignTemplates(id)
        .then((response) => {
          dispatch(setCampaignTemplates(response.data));
          if (spinner) dispatch(decreaseSpinner());
        })
        .catch(() => {
          if (spinner) dispatch(decreaseSpinner());
        });
    },
    [api, dispatch]
  );

  const createCampaignTemplate = useCallback(
    (id: number, data: CreateCampaignTemplatePayloadType, callback?: (template: CampaignTemplate | null) => void) => {
      setBusy(true);
      api
        .createBrandCampaignTemplate(id, data)
        .then((response: AxiosResponse) => {
          message.success('Campaign template was successfully created');
          setBusy(false);
          if (callback) callback(response.data);
          dispatch(addCampaignsTemplate(response.data));
          onChangeErrors([]);
        })
        .catch((error: AxiosError) => {
          if (callback) callback(null);
          setBusy(false);
          if (error.response?.data.statusCode === 422) {
            const errors = errorParser(error.response?.data);
            onChangeErrors(errors);
          }
        });
    },
    [api, dispatch]
  );

  const updateCampaignTemplate = useCallback(
    (brandId: number, templateId: number, data: CreateCampaignTemplatePayloadType, cb?: () => void) => {
      setBusy(true);
      api
        .updateBrandCampaignTemplate(brandId, templateId, data)
        .then((response: AxiosResponse) => {
          message.success('Campaign template was successfully updated');
          dispatch(setCampaign(response.data));
          dispatch(updateCampaignsTemplate(response.data));
          onChangeErrors([]);
          if (cb) cb();
        })
        .catch((error: AxiosError) => {
          if (error.response?.data.statusCode === 422) {
            const errors = errorParser(error.response?.data);
            onChangeErrors(errors);
          }
        });
    },
    [api, dispatch]
  );

  const deleteCampaignTemplate = useCallback(
    (id: number, templateId: number) => {
      api.deleteBrandCampaignTemplate(id, templateId).then(() => {
        message.success('Campaign template was successfully deleted');
        dispatch(removeCampaignTemplate(templateId));
      });
    },
    [api, dispatch]
  );

  return {
    errors,
    busy,
    campaignTemplates,
    getCampaignTemplates,
    createCampaignTemplate,
    updateCampaignTemplate,
    deleteCampaignTemplate
  };
};

export default useBrandCampaignTemplates;
